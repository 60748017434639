
import { defineComponent, ref, onBeforeMount } from "vue";
import LazyImage from "@/components/common/LazyImage.vue";
import OTable, { Record } from "@/components/common/Table.vue";
import { decimalizeNumber } from "@/utils/numberUtils";
import { rankListReq, WhaleItem } from "@/apis";
import { useRouter } from "vue-router";
import { setImgSize } from "@/utils/setImgSizeUtil";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "RankList",
  components: {
    OTable,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const dom = ref();
    const columns = ref();
    const dataSource = ref<Record[]>([]);

    const loading = ref(false);
    const currentPage = ref(0);
    const pageSize = ref(20);

    const formatData = (data: WhaleItem[]) => {
      const arr: Record[] = (data as WhaleItem[]).map((item) => {
        return {
          avatarUrl: item.socialInfo.twitterAvatar || item.maxValImg,
          name:
            item.socialInfo.twitterName || item.ensName || item.walletAddress,
          nftNum: item.holdingNfts,
          flipRate: item.flipRate,
          holdingValueEth: item.holdingValueEth,
          d7Activities: item.d7Activities,
          d7ReturnRate: item.d7PaidSpent ? item.d7ReturnRate : undefined,
          d30ReturnRate: item.d30PaidSpent ? item.d30ReturnRate : undefined,
          walletAddress: item.walletAddress,
        };
      });
      return arr;
    };

    const getRankList = async () => {
      loading.value = true;
      const [err, res] = await rankListReq();
      loading.value = false;
      if (err) {
        console.error(err);
      }
      if (res) {
        dataSource.value = formatData(res.data);
      }
    };

    const handleClickRow = (record: Record) => {
      const { walletAddress } = record;
      let routeData = router.resolve({
        name: "WalletDetail",
        params: {
          addr: walletAddress,
        },
      });
      window.open(routeData.href, "_self");
    };

    onBeforeMount(() => {
      columns.value = [
        {
          key: "nfts",
          title: `${t("general.user")} / ${t("general.nfts")}`,
          uppercase: true,
          render: (h: any, record: Record) => {
            const { avatarUrl, name, nftNum, index } = record;
            return h(
              "div",
              {
                class: "flex-1 w-0 flex items-center",
              },
              [
                h(
                  "p",
                  {
                    class: `text-sm text-primary-100 mr-3${
                      (index as number) > 3 ? " opacity-50" : ""
                    }`,
                  },
                  index
                ),
                h(LazyImage, {
                  src: setImgSize(avatarUrl as string, "72x72"),
                  class:
                    "w-11 h-11 rounded-full mr-2 border-4 border-solid border-n2",
                }),
                h(
                  "div",
                  {
                    class: "overflow-hidden flex-1",
                  },
                  [
                    h(
                      "p",
                      {
                        class:
                          "text-sm font-bold whitespace-nowrap overflow-hidden overflow-ellipsis leading-none mb-3",
                        title: name,
                      },
                      name
                    ),
                    h(
                      "p",
                      {
                        class: "text-xs text-customSet-4 leading-none",
                      },
                      nftNum + " " + t("general.nfts")
                    ),
                  ]
                ),
              ]
            );
          },
        },
        {
          key: "flipRate",
          title: t("general.fliprate"),
          uppercase: true,
          align: "right",
          width: 140,
          sortable: true,
          format: (record: Record) => {
            const value = record.flipRate;
            return value ? decimalizeNumber(Number(value) * 100) + "%" : "--";
          },
        },
        {
          key: "holdingValueEth",
          title: t("general.value(unit)", { unit: t("general.eth") }),
          uppercase: true,
          align: "right",
          width: 150,
          sortable: true,
          format: (record: Record) => {
            const value = record.holdingValueEth;
            return decimalizeNumber(Number(value), false, "ETH");
          },
        },
        {
          key: "d7Activities",
          title: t("views.Rank.nDaysActivity", { n: 7 }),
          uppercase: true,
          align: "right",
          width: 140,
        },
        {
          key: "d7ReturnRate",
          title: t("views.Rank.nDaysReturn", { n: 7 }),
          uppercase: true,
          align: "right",
          width: 170,
          sortable: true,
          render: (h: any, record: Record) => {
            const { d7ReturnRate } = record;
            return h(
              "div",
              {
                class: "flex-1 text-sm",
              },
              h(
                "p",
                {
                  class: `text-right ${
                    d7ReturnRate &&
                    (d7ReturnRate > 0
                      ? " text-green"
                      : d7ReturnRate < 0
                      ? " text-red"
                      : "")
                  }`,
                },
                d7ReturnRate
                  ? decimalizeNumber(Number(d7ReturnRate) * 100, true) + "%"
                  : "---"
              )
            );
          },
        },
        {
          key: "d30ReturnRate",
          title: t("views.Rank.nDaysReturn", { n: 30 }),
          uppercase: true,
          align: "right",
          width: 170,
          sortable: true,
          render: (h: any, record: Record) => {
            const { d30ReturnRate } = record;
            return h(
              "div",
              {
                class: "flex-1 text-sm",
              },
              h(
                "p",
                {
                  class: `text-right ${
                    d30ReturnRate &&
                    (d30ReturnRate > 0
                      ? " text-green"
                      : d30ReturnRate < 0
                      ? " text-red"
                      : "")
                  }`,
                },
                d30ReturnRate
                  ? decimalizeNumber(Number(d30ReturnRate) * 100, true) + "%"
                  : "---"
              )
            );
          },
        },
      ];
      getRankList();
    });

    const onTablePageChange = () => {
      dom.value.scrollTo(0, 0);
    };

    return {
      columns,
      dataSource,
      loading,
      pageSize,
      currentPage,
      dom,
      handleClickRow,
      onTablePageChange,
      t,
    };
  },
});
